import * as React from "react";
import { graphql } from "gatsby";

import Breadcrumps from "@components/Breadcrumbs";
import Seo from "@components/Seo";

function PrivacyPolicePage({ data }) {
  const { seo } = data.allWpPage.nodes[0];
  const breadcrumbs_data = [{ name: "Polityka prywatności" }];
  const page_content = data.allWpPage.nodes[0];

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <div className="privacy-policy-page">
        <div className="container">
          <Breadcrumps data={breadcrumbs_data} />
          <main>
            <h1>{page_content.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: page_content.content }} />
          </main>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicePage;

export const query = graphql`
  {
    allWpPage(filter: { title: { eq: "Privacy policy" } }) {
      nodes {
        content
        title
        seo {
          title
          metaDesc
        }
      }
    }
  }
`;
